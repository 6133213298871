import isEqual from "lodash.isequal";
import { templDataFixToSave } from "../../../../../Firebase/Firestore/Faktura/TemplateFirestore";
import { currenciesList } from "../../../../Data/currencies";
import { getIndexFromIso } from "../../../../Components/Tables/DataTable/FakturaTable";

export const compareObjects = (objOne, objTwo) =>
  areObjectsSame(objOne, objTwo);

export function areObjectsSame(objOne, objTwo) {
  return isEqual(objOne, objTwo);
}

export function areArraysSame(arrOne, arrTwo) {
  return isEqual(arrOne, arrTwo);
}

export function areMemoPropsEqual(propOne, propTwo) {
  return isEqual(propOne, propTwo);
}

export function handleTemplateData(templateData) {
  if (!templateData) return {};
  let finalData = {
    ...(templateData.name && { name: templateData.name }),
    ...(templateData.typ && { typ: templateData.typ }),
    ...(templateData.dueDateIndex && {
      dueDateIndex: templateData.dueDateIndex,
    }),
    ...("payMethod" in templateData && { payMethod: templateData.payMethod }),
    ...(templateData.hideBank && { hideBank: templateData.hideBank }),
    //
    ...(templateData.dphCalc && { dphCalc: templateData.dphCalc }),
    ...(templateData.dphCarr && { dphCarr: templateData.dphCarr }),
    ...(templateData.paidText && { paidText: templateData.paidText }),
    //
    ...("note" in templateData && { note: templateData.note }),
    ...("note" in templateData && {
      showNote: templateData.note ? true : false,
    }),
  };

  if (templateData.curr) {
    for (let i = 0; i < currenciesList.length; i++) {
      if (currenciesList[i].iso === templateData.curr) {
        finalData.curr = i;
        break;
      }
    }
  }

  return finalData;
}

export function getTemplateDataFinalized(data, rows, navigate) {
  try {
    // Fixing data to save - removing control variables, etc.
    let fakturaDataSorted = templDataFixToSave(data);

    // Bankovni ucet - usporadani dat
    if (!("bank" in fakturaDataSorted)) {
      if (navigate) {
        navigate("/settings/banka/pridat");
      }
      return;
    }

    let bankAcc = fakturaDataSorted.bankObj;
    delete fakturaDataSorted.bankAccounts;
    delete fakturaDataSorted.bankObj;

    let bankFinal = { ...bankAcc };

    fakturaDataSorted.bank = bankFinal;

    // Finalizing data
    let finalData = {
      ...fakturaDataSorted,
      items: rows,
    };

    if (finalData.dphCalc === 0) {
      delete finalData.dphCalc;
    }
    if (finalData.dphCarr === 0) {
      delete finalData.dphCarr;
    }
    if (finalData.hideBank === 0) {
      delete finalData.hideBank;
    }
    if (finalData.lang === 0) {
      delete finalData.lang;
    }
    if (finalData.paidText === 0) {
      delete finalData.paidText;
    }

    finalData.curr = currenciesList[finalData.curr || 0].iso;

    return finalData;
  } catch (error) {
    throw new Error(`ERROR getFakturaDataFinalized `, error);
  }
}

export function getTemplatePreloadToInit(preloadData) {
  if (!preloadData) return {};
  return {
    ...(preloadData.supplier?.name && { name: preloadData.supplier.name }),
    ...(preloadData.typ && { typ: preloadData.typ }),
    ...(preloadData.payMethod && { payMethod: preloadData.payMethod }),
    ...(preloadData.hideBank && { hideBank: preloadData.hideBank }),

    ...(preloadData.dphCalc && { dphCalc: preloadData.dphCalc }),
    ...(preloadData.dphCarr && { dphCarr: preloadData.dphCarr }),
    ...(preloadData.paidText && { paidText: preloadData.paidText }),

    // Curr
    ...(preloadData.curr &&
      preloadData.curr.iso && { curr: getIndexFromIso(preloadData.curr.iso) }),

    ...("note" in preloadData && { note: preloadData.note }),
    ...("note" in preloadData && { showNote: true }),
  };
}
